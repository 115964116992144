export const API_URLS = {
  accounts: {
    org: {
      list: '/api/v1/org/',
    },
  },
  competition: {
    coach: {
      list: '/api/v1/coach/',
    },
    list: '/api/v1/competition/',
    participant: {
      list: '/api/v1/competition-participant/',
      current: '/api/v1/competition-participant/current/',
      currentAsXLSX: '/api/v1/competition-participant/current2/',
    },
    participantFiles: {
      list: '/api/v1/competition-participant-file/'
    },
  },
  media: {
    list: '/api/v1/media/'
  },
  translation: {
    competition: '/api/v1/translation/all.json'
  },
  user: {
    auth: '/api/v1/auth/',
    me: '/api/v1/auth/user/me/',
    login: '/api/v1/auth/token/',
    logout: '/api/v1/auth//logout/',
    token: '/api/v1/auth/token/',
  }
}