import { API_URLS } from 'api/urls';
import { makeDispatch } from 'redux/utils';

export const SET_COMPETITION = 'SET_COMPETITION';
export const FETCH_COACH_LIST = 'FETCH_COACH_LIST';
export const FETCH_COMPETITION_LIST = 'FETCH_COMPETITION_LIST';
export const FETCH_COMPETITION_ONE = 'FETCH_COMPETITION_ONE';
export const FIND_COMPETITION_PARTICIPANT = 'FIND_COMPETITION_PARTICIPANT';
export const GET_COMPETITION_PARTICIPANT = 'GET_COMPETITION_PARTICIPANT';
export const CREATE_COMPETITION_PARTICIPANT = 'CREATE_COMPETITION_PARTICIPANT';
export const CREATE_COMPETITION_PARTICIPANT_FILE = 'CREATE_COMPETITION_PARTICIPANT_FILE';
export const UPDATE_COMPETITION_PARTICIPANT = 'UPDATE_COMPETITION_PARTICIPANT';

export const setCompetition = (data) => {
  return {
    type: SET_COMPETITION,
    data
  }
};

export const fetchCoachList = ({ url, params }) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FETCH_COACH_LIST,
    url: url || API_URLS.competition.coach.list
  });
};

export const fetchList = ({ url, params }) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FETCH_COMPETITION_LIST,
    url: url || API_URLS.competition.list
  });
};

export const fetchOne = ({ url, params }) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FETCH_COMPETITION_ONE,
    url: url || API_URLS.competition.list
  });
};

export const findParticipant = ({ url, params }) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FIND_COMPETITION_PARTICIPANT,
    url: url || API_URLS.competition.participant.list
  });
};

export const getParticipant = ({ url, params }) => (dispatch, getState) => {
  return makeDispatch({
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: GET_COMPETITION_PARTICIPANT,
    url: url || API_URLS.competition.participant.current
  });
};

export const createParticipant = ({ url, data, params }) => (dispatch, getState) => {
  return makeDispatch({
    data,
    dispatch,
    method: 'POST',
    params,
    state: getState(),
    type: CREATE_COMPETITION_PARTICIPANT,
    url: url || API_URLS.competition.participant.list
  });
};

export const updateParticipant = ({ url, data, params }) => (dispatch, getState) => {
  return makeDispatch({
    data,
    dispatch,
    method: 'PATCH',
    params,
    state: getState(),
    type: UPDATE_COMPETITION_PARTICIPANT,
    url
  });
};

export const createCPMF = ({ url, data, params }) => (dispatch, getState) => {
  return makeDispatch({
    data,
    dispatch,
    method: 'POST',
    params,
    state: getState(),
    type: CREATE_COMPETITION_PARTICIPANT_FILE,
    url: url || API_URLS.competition.participantFiles.list,
  });
};

export const fetchCurrentAsXLSX = ({ url, params }) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FETCH_COMPETITION_ONE,
    url: url || API_URLS.competition.participant.currentAsXLSX
  });
};